.App {
  text-align: center;
}
#infosecLogo {
  width: auto;
  height: 80px;
}
#fabrica40Logo {
  max-width: 100%;
  max-height: 150px;
}

.form-box {
    color: #9ba5a8;
    border-radius: 3px;
    margin-bottom: 15px;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}

.form-box h1 {
    color: #333;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-box hr {
  margin: 0 -30px 20px;
}

.red-text {
  color: red;
}

/* styles from prev project -> transition all of them to mui/material in the near future */
.grafic-div {
  /* border-top: 2px solid #1ab394; */
  /* margin-bottom: 2vh; */
  padding-top: 3vh;
  padding-bottom: 1vh;
}
.graph-results {
	/* margin-top: 100px; */
  background-color: #fff;
}
.title-div {
  font-size: 2.6rem;
  text-align: center;
  background-color: #2f4858;
  color: #fff;
  padding: 1vh 0;
  margin-bottom: 0;
}
#chartdivCC21,
#chartdivCC22,
#chartdivCC23,
#chartdivS41,
#chartdivS42,
#chartdivS43,
#chartdivRP53,
#chartdivRP54 {
  padding-top: 3vh;
  padding-bottom: 2vh;
  margin-left: 10vw;
  margin-right: 10vw;
}
#general-results-table {
  margin-bottom: 2vh;
}
.table {
	width: 100%;
}
.table-header {
  background-color: #323232;
  color: #fff;
}
.table thead th {
  border-bottom: none;
  font-size: 1.2rem;
}
.table td,
.table th {
  border-top: none;
}
.table1 {
  background-color: #83c0dd;
  color: #232323;
}
.table2 {
  background-color: #7fa1d8;
  color: #232323;
}
.table3 {
  background-color: #838add;
  color: #232323;
}
.table4 {
  background-color: #9784db;
  color: #232323;
}
.table5 {
  background-color: #b384df;
  color: #232323;
}
.table6 {
  background-color: #d086e0;
  color: #232323;
}
.subcapitol {
  background-color: #d9effa;
  font-size: 1.1rem;
  border-top: none;
}
.subcapitol th {
  padding-left: 1vw;
}
.subcapitol:hover {
  background-color: #c7e3f0 !important;
}
.indicator-general-row {
  background-color: #9baebc;
}

.indicator-general-row:hover {
  background-color: #9baebc !important;
}

.indicator-general {
  background-color: #a9d18e;
}
.indicator-general:hover {
  background-color: #a9d18e !important;
}
@media (min-width: 220px) and (max-width: 600px) {
  .title-div {
    font-size: 1.5rem;
  }

  .custom-css-CD .sv_q_rating_item .sv_q_rating_item_text {
    border-left: 1px solid #4d4d4d !important;
    font-size: 0.9rem;
  }

  .custom-css-MD .sv_test .sv_q_title {
    text-align: center;
  }

  .custom-css-MD .sv_test .sv_q_title span {
    font-size: 1.5rem;
  }

  .custom-css-MD
    .sv_container
    .sv_body
    .sv_p_root
    table.sv_q_matrix
    td:first-child {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 5vh;
    background-color: #232323;
    color: #fff;
  }
  .custom-css-CD
    .sv_container
    .sv_body
    .sv_p_root
    table.sv_q_matrix
    td:first-child {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 5vh;
  }

  .custom-css-MD .sq-row-custom .sv_q_m_cell_text {
    border-bottom: 3px solid #232323;
    border-top: 1px solid #232323;
  }

  .custom-css-MD .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-align: center;
    border-left: 3px solid #232323;
    border-right: 2px solid #232323;
    border-radius: 5px;
  }
  .custom-css-CD .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-align: center;
  }
  .custom-css-MD .sv_test .html-root h1 {
    font-size: 2rem;
    /* font-weight: bold; */
  }
  .custom-css-MD .html-root p {
    font-size: 1.2rem;
  }

  .custom-css-CD .sv_test .html-root h1 {
    font-size: 2.2rem;
    /* font-weight: bold; */
  }
  .custom-css-CD .html-root p {
    font-size: 1.2rem;
  }

  .custom-css-MD .sv_q_description {
    text-align: center;
  }

  .custom-css-MD .sv_q_m_cell_text {
    outline: none;
  }

  #chartdivCC21,
  #chartdivCC22,
  #chartdivCC23,
  #chartdivS41,
  #chartdivS42,
  #chartdivS43,
  #chartdivRP53,
  #chartdivRP54 {
    padding-top: 3vh;
    padding-bottom: 2vh;
    margin-left: 0vw;
    margin-right: 0vw;
  }
}